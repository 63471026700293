import "./home.css"
import React from 'react';
import { Space } from 'antd';
import { Button } from '@chakra-ui/react'
import Logo from "../assets/ZG_Logo.png"
import { useNavigate } from "react-router-dom";

import {
    LinkedinOutlined,
    GithubOutlined,
    MailOutlined,
} from '@ant-design/icons';

export default function Home () {
    const navigator = useNavigate();

    const goToBacklog = () => {
        navigator("/backlog");
    };

    const paragraph_1 = `
        Nice to meet you!!! I'm currently a Software Engineer at Qlik! I studied Computer Science at Boston University as my undergraduate education.
        When I'm not in a class, meeting, or spending many hours in front of my laptop pulling my hair out, you'll probably catch me reading a odd book, watching hilariously bad reality TV (Watch Mountain Monsters!),
        or flipping through a catalogue of bizarre wikipedia pages (Look up Gävle goat!).
    `

    const paragraph_2 = `
        On the academic side, I have a keen interest in Statistical Machine Learning and Deep Learning with Graphs. I had the pleasure to have opportunities to explore data through the eyes of topology and uncovering many proofs!
        It is immensely striking how we can do such complex operations with just some rocks and electricity!
    `

    const finisher = `
        Feel free to check out the links below to contact me! :)
    `

    return (
        <>
            <div className="master_container">
                <div className="navbar">
                    <div className="logo">
                        <img src={Logo} alt="logo" className="logo"></img>
                    </div>
                    <div className="navButtons_containers">
                        <Button colorScheme='blue' size='md' className="buttons" onClick={goToBacklog}>
                            Book Backlog
                        </Button>
                    </div>
                    
                </div>
                <div className="content_container"> 
                    <div>
                        <h1 id="greeting">Hello, I'm Zach!</h1>
                    </div>
                    <div style={{textAlign: "center"}}>
                        <h2 id="tag">Student, Researcher, Modern (Finite) Turing Machine Engineer.</h2>
                    </div>
                    <div id="text_container">
                        <div className="text">
                            <p>{paragraph_1}</p>
                        </div>
                        <div className="text">
                            <p>{paragraph_2}</p>
                        </div>
                        <div className="text">
                            <p>{finisher}</p>
                        </div>
                        </div>
                            <Space size={"large"}>
                                <a href="https://www.linkedin.com/in/zachary-gou-72b317220/" target="_blank" rel="noopener noreferrer">
                                    <LinkedinOutlined className="icon-style" />
                                </a>
                                <a href="https://github.com/Zachary1575" target="_blank" rel="noopener noreferrer">
                                    <GithubOutlined className="icon-style"/>
                                </a>
                                <a href="mailto:zacharygou@gmail.com">
                                    <MailOutlined className="icon-style"/>
                                </a>
                            </Space>
                        <div>
                    </div>
                </div>
            </div>
        </>
    )
}
