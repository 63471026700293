import "./backlog.css"
import Logo from "../assets/ZG_Logo.png"
import { Button } from '@chakra-ui/react'
import { Divider } from 'antd';

import { useNavigate } from "react-router-dom";


export default function Backlog() {
    const navigator = useNavigate();

    const goToHome = () => {
        navigator("/");
    };

    const unreadBooks = [
        {
            title: "Distributed Algorithms by Nancy A. Lynch",
            comment: `I heard it was a compilation of Leslie's paper's. Complicated but wanna read so bad! Some owe Leslie's contribution towards
            Distributed System like the Paxos Algorithm as the foundations of the Internet!
            `
        },
        {
            title: "The Disappearing Spoon: And Other True Tales of Madness, Love, and the History of the World from the Periodic Table of the Elements by Sean Kean",
            comment: `Heard this was a very popular chem book. I was recommended back in High School to read this by my chem teacher
            and I wanna explore chem again!
            `
        },
        {
            title: "An Introduction to Statistical Learning: With Applications in R by James et al.",
            comment: `I already read/skimmed through this book. However, it has so many dense concepts and goodies that its a great
            reread! I need to find time to sit down and finish the exercises that I skipped!
            `
        },
        {
            title: "An Imaginary Tale by Paul J. Nahin",
            comment: "Heard this one was a great book! It tells the story of how complex numbers arise!"
        },
    ]

    const readBooks = [
        {
            title: "[Currently Reading/Doing] Proofs by Jay Cummings",
            comment: `Very enjoyable exercise book! I need to get back in the groove for proofs so this is my go-to!`
        },
        {
            title: "[Currently Reading] Fermats Enigma: The Epic Quest to Solve the World's Greatest Mathematical Problem by Simon Singh",
            comment: `Super interesting book. I am amazed by the history and context behind mathematicians. 
            Some are like borderline lunatics like Pythagoreas and the Brotherhood!`
        },
        {
            title: "[Done!] And Then There Were None by Agatha Christie",
            comment: `What a read! Finished in 2 days! I think this is a classic when it comes to murder mystery.
            I believe that many modern murder mysteries and such take inspiration from this book. Agatha Christie pulls
            a saw move (guess which one!) at the end of the book.
            `
        },
    ]


    const short_blurb = `
        Here is where I list some books and literature that I want to get through and those that I have read. I recently started this
        list, so I may not put everything I have read from the past. It's hard to track down what you have read years ago... The list will be updated in batches depending
        on my reading speed! If you find something that is interesting or would like to discuss some literature, don't be afraid
        to hit me up ;)
    `

    const finisher = "Coffee will be on me!"

    return(
        <>
            <div className="master_container">
                <div className="navbar">
                    <div className="logo">
                        <img src={Logo} alt="logo" className="logo"></img>
                    </div>
                    <div className="navButtons_containers">
                        <Button colorScheme='blue' size='md' className="buttons" onClick={goToHome}>
                            Home
                        </Button>
                    </div>
                    
                </div>
                <div className="content_container"> 
                    <div style={{textAlign: "center"}}>
                        <div>
                            <h1 id="backlog-title">The Backlog</h1>
                        </div>
                        <h2 id="tag">The Dusty Backlog of Forgotten Wants.</h2>
                    </div>
                    <div id="text_container">
                        <div className="text">
                            <p>{short_blurb}</p>
                        </div>
                        <div className="text-finisher">
                            <p>{finisher}</p>
                        </div>
                        <Divider/>
                        <div style={{textAlign: "center"}}>
                            <h2 id="tag">Need to Read.</h2>
                        </div>
                        {
                            unreadBooks.map((e) => {
                                return(
                                    <div className="text">
                                        <h2 id="book-title">{e.title}</h2>
                                        <p>{e.comment}</p>
                                    </div>
                                )
                            })
                        }
                        <Divider/>
                        <div style={{textAlign: "center"}}>
                            <h2 id="tag">Already Read / Currently Reading.</h2>
                        </div>
                        {
                            readBooks.map((e) => {
                                return(
                                    <div className="text">
                                        <h2 id="book-title">{e.title}</h2>
                                        <p>{e.comment}</p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}