import './App.css';
import Home from './pages/home';
import Backlog from './pages/backlog';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

function App() {
  return (
    <>
      <Router>
        <Routes>
         <Route path="/" element={<Home/>} />
         <Route path="/backlog" element={<Backlog/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
